import * as React from "react"

const Image = ({ url, width, alt }) => {
  const containerWidth = 960
  const defaultWidth = containerWidth > width ? width : containerWidth
  return (
    <picture>
      {defaultWidth > 480 && (
        <source
          srcSet={`${url}?w=480&auto=format`}
          media="(max-width: 480px)"
        />
      )}
      {defaultWidth > 640 && (
        <source
          srcSet={`${url}?w=640&auto=format`}
          media="(max-width: 640px)"
        />
      )}
      {defaultWidth > 768 && (
        <source
          srcSet={`${url}?w=768&auto=format`}
          media="(max-width: 768px)"
        />
      )}
      <img
        src={`${url}?w=${defaultWidth}&auto=format`}
        alt={alt}
        className="mb-0"
      />
    </picture>
  )
}

export default Image
