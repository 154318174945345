/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
            instagram
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (
    <div className="md:flex mb-16">
      <div>
        <StaticImage
          className="md:mr-4 mx-auto mb-0 text-center rounded-full z-10"
          layout="fixed"
          formats={["AUTO", "WEBP", "AVIF"]}
          src="../images/profile.png"
          width={80}
          height={80}
          alt="Profile picture"
        />
      </div>
      {author?.name && (
        <div>
          <p className="font-bold mb-3 text-xl">{author.name}</p>
          <p className="mb-2 whitespace-pre-line">{author?.summary || null}</p>
          <div className="flex flex-row">
            <div className="bio-twitter bio-button">
              <a
                href={`https://twitter.com/${social?.twitter || ``}`}
                target="_brank"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </div>
            <div className="bio-instagram bio-button">
              <a
                href={`https://www.instagram.com/${social?.instagram || ``}`}
                target="_brank"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Bio
