import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import { CardActionArea } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faClock,
  faFolderOpen,
  faSync,
} from "@fortawesome/free-solid-svg-icons"
import Image from "./image"

const ArticleCard = ({ post }) => {
  const title = post.title

  return (
    <div className="article-card w-full mb-5 md:w-1/3 md:px-2">
      <Card>
        <CardActionArea>
          <Link to={`/blog/${post.slug}/`} itemProp="url">
            <CardMedia className="relative">
              {post.thumbnail?.url ? (
                <Image
                  url={post.thumbnail.url}
                  width={post.thumbnail.width}
                  alt="サムネイル"
                />
              ) : (
                <StaticImage
                  layout="fullWidth"
                  aspectRatio={1.91 / 1}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  src="../../images/noimage.png"
                  alt="noImage"
                />
              )}
              <div className="absolute top-0 left-0 text-xs text-white bg-indigo-500 bg-opacity-80 px-2 py-0.5">
                <FontAwesomeIcon icon={faFolderOpen} className="mr-1" />
                {post.category?.name}
              </div>
            </CardMedia>
            <CardContent className="h-32 overflow-hidden">
              <div className="text-gray-800 font-medium">{title}</div>
            </CardContent>
            <div className="flex flex-row justify-end">
              <div className="mb-1 mr-3 text-gray-500 text-sm">
                <FontAwesomeIcon icon={faClock} className="mr-1" />
                {post.createdAt}
              </div>
              {post.updatedAt !== post.createdAt && (
                <div className="mb-1 mr-3 text-gray-500 text-sm">
                  <FontAwesomeIcon icon={faSync} className="mr-1" />
                  {post.updatedAt}
                </div>
              )}
            </div>
          </Link>
        </CardActionArea>
      </Card>
    </div>
  )
}

export default ArticleCard
