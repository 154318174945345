import * as React from "react"
import { navigate } from "gatsby"
import Pagination from "@material-ui/lab/Pagination"
import ArticleCard from "./atoms/articleCard"

const ArticleList = ({ microCmsData }) => {
  const handleChange = (event, value) => {
    value === 1 ? navigate(`/`) : navigate(`/page/${value}`)
  }

  return (
    <>
      <div className="flex flex-wrap mb-6">
        {microCmsData.nodes.map(post => (
          <ArticleCard post={post} key={post.blogId} />
        ))}
      </div>
      <div className="mb-6 text-center">
        <Pagination
          defaultPage={microCmsData.pageInfo.currentPage}
          count={microCmsData.pageInfo.pageCount}
          variant="outlined"
          color="primary"
          className="inline-block"
          onChange={handleChange}
        />
      </div>
    </>
  )
}

export default ArticleList
